<template>
  <div class="container page-container">
    <Breadcrumbs v-if="isPackages" currentpage="Pakketten" />
    <Breadcrumbs
      v-if="!isPackages"
      :bread-crumbs="breadCrumbs"
      :currentpage="category.Description"
    />
    <h1 v-if="category.Description">{{ category.Description }}</h1>
    <h1 v-if="searchValue">Zoeken naar: {{ searchValue }}</h1>
    <p class="pb-0 mb-0 categoryDescription">{{ category.Notes }}</p>
    <p v-if="itemCount > 0" class="item-count float-right d-none d-lg-block">
      {{ itemCount }} resultaten gevonden
    </p>
    <Items
      v-if="category.CategoryID != null || isPackages || searchValue"
      :search-term="searchValue"
      :categoryid="parsedCategoryID"
      @totals="setTotals($event)"
    />
  </div>
</template>

<script>
// @ is an alias to /src
import Items from '@/components/items/Index'
import Breadcrumbs from '@/components/header/BreadCrumbs'
import { getCategory } from '@/services/CategoryService'
import { generateBreadCrumbs } from '@/services/Breadcrumbs'
import GetCategorySEODataBySlug from '@/services/category/GetCategorySEODataBySlug'

export default {
  name: 'Shop',
  components: {
    Items,
    Breadcrumbs
  },
  data() {
    return {
      category: [],
      parentCategory: [],
      breadCrumbs: [],
      itemCount: 0,
      categoryID: null,
      isPackages: false,
      categoryTitle: null
    }
  },
  metaInfo: function() {
    return {
      title: `${this.categoryTitle ?? 'Home'}`,
      titleTemplate: '%s - Vlaams-Brabant Uitleendienst',
      meta: [
        {
          name: `description`,
          content: `${this.metaDescription}`,
          vmid: 'og:meta-description'
        }
      ],
      link: [
        {
          rel: 'canonical',
          href: `${this.generateCanonical()}`
        }
      ]
    }
  },
  computed: {
    metaDescription() {
      if (this.categoryTitle) {
        return `Categorie ${this.categoryTitle}`
      } else {
        return `Officiële website van de provincie Vlaams-Brabant Uitleendienst`
      }
    },
    parsedCategoryID: function() {
      if (this.category.CategoryID) {
        return parseInt(this.category.CategoryID)
      } else {
        return null
      }
    },

    searchValue: function() {
      if (this.$route.params.searchTerm) {
        return this.$route.params.searchTerm.toLowerCase()
      } else {
        return null
      }
    }
  },
  watch: {
    async $route() {
      // this.category = await getCategory({
      //   categoryID: this.$route.params.categoryID,
      //   slug: true
      // })
      this.breadCrumbs = await generateBreadCrumbs({
        categoryID: this.$route.params.subcategoryID
      })
    }
  },
  async beforeMount() {
    if (this.$route.name === 'packages') {
      this.categoryTitle = 'Pakketten'
    }
    if (this.$route.params.categoryID != null) {
      let categoryDescription =
        this.$route.params.subcategoryID ?? this.$route.params.categoryID
      if (categoryDescription.includes('-')) {
        categoryDescription = categoryDescription.replace('-', ' ')
      }
      // set first letter to uppercase
      categoryDescription =
        categoryDescription.charAt(0).toUpperCase() +
        categoryDescription.slice(1)

      this.categoryTitle = categoryDescription

      let categorySEOData = {}

      if (this.$route.params.categoryID) {
        categorySEOData = await GetCategorySEODataBySlug({
          slug:
            this.$route.params.subcategoryID ?? this.$route.params.categoryID
        })
      }

      this.categoryTitle = categorySEOData[0]?.Description
      this.category = await getCategory({
        categoryID: this.$route.params.subcategoryID,
        slug: true
      })

      this.breadCrumbs = await generateBreadCrumbs({
        categoryID: this.$route.params.subcategoryID
      })
    } else {
      this.breadCrumbs = await generateBreadCrumbs({
        categoryID: this.category.CategoryID
      })
    }
    this.isPackages = this.$route.name === 'packages'
  },
  methods: {
    generateCanonical() {
      let url = window.location.href
      // strip url params from url
      url = url.split('?')[0]

      // if url contains /search then strip it
      if (url.includes('/zoeken')) {
        url = url.split('/zoeken')[0]
      }

      return url
    },
    getCategory: function() {
      if (this.category) {
        return this.category.Description
      } else {
        return null
      }
    },
    setTotals: function(event) {
      this.itemCount = event
    }
  }
}
</script>
<style scoped lang="scss">
.item-count {
  font-size: 22px;
  color: #070808;
  opacity: 0.25;
  font-family: 'DecimaPro-Bold';
  margin-top: -60px;
}
h1 {
  font-family: 'DecimaPro-bold';
  font-size: 50px;
}
@media only screen and (max-width: 500px) {
  h1 {
    font-size: 40px;
  }
}
.breadcrumbs {
  font-size: 18px;
  margin-bottom: 20px;
}
.categoryDescription {
  font-size: 20px;
  font-family: 'Roboto';
}
</style>

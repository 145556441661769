<template>
  <div class="compositionGrid">
    <!-- START 1 image -->
    <b-row v-if="images.length === 1">
      <b-col v-for="imageUrl in images" :key="imageUrl" cols="12">
        <b-img
          v-if="imageUrl"
          fluid
          :alt="alt"
          crossorigin="anonymous"
          class="rounded"
          :src="imageUrl"
        />
      </b-col>
    </b-row>
    <!-- END 1 image -->
    <!-- START 2 images -->
    <b-row v-if="images.length === 2">
      <b-col
        v-for="imageUrl in images"
        :key="imageUrl"
        class="2-images"
        cols="6"
      >
        <b-img
          v-if="imageUrl"
          fluid
          :alt="alt"
          crossorigin="anonymous"
          class="rounded"
          :src="imageUrl"
        />
      </b-col>
    </b-row>
    <!-- END 2 images -->
    <!-- START 3 images -->
    <b-row v-if="images.length === 3">
      <b-col
        v-for="imageUrl in images"
        :key="imageUrl"
        class="3-images"
        cols="6"
      >
        <b-img
          v-if="imageUrl"
          fluid
          :alt="alt"
          crossorigin="anonymous"
          class="rounded"
          :src="imageUrl"
        />
      </b-col>
    </b-row>
    <!-- END 3 images -->
    <!-- START 4 images or more -->
    <b-row v-if="images.length >= 4">
      <b-col
        v-for="imageUrl in images.slice(0, 4)"
        :key="imageUrl"
        class="4-images"
        cols="6"
      >
        <b-img
          v-if="imageUrl"
          fluid
          :alt="alt"
          crossorigin="anonymous"
          class="rounded"
          :src="imageUrl"
        />
      </b-col>
    </b-row>
    <!-- END 4 images or more -->
  </div>
</template>

<script>
export default {
  props: {
    alt: {
      type: String,
      default: function() {
        return ''
      }
    },
    images: {
      type: Array,
      default: function() {
        return []
      }
    }
  }
}
</script>

<style></style>
